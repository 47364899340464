//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['menu','webset'],
    data() {
        return {
            active: '',
        }
    },
    methods: {
        handleSelect(e) {
            console.log(e)
        },
		goto(){
			
		}
    }
}
