//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import MenuBox from '@/components/Menu'
export default {
	props:['logo','leimulist','webset'],
    components: {MenuBox},
    data() {
        return {
			uniacid:0,
            //uniacid:17,
            keyword: '',
            menu: [
                {
                    name: '首頁',
                    path: '/home',
                },
                {
                    name: 'loading',
                    children: [
                        {name: '类目1', path: '/category'},
                        {name: '类目2', path: '/category'},
                        {name: '类目3', path: '/category'},
                        {name: '类目4', path: '/category'},
                    ],
                }
                
            ]
        }
    },
	   created(){
		  this.gettopmenu()
		   
		   },
    methods: {
		//获取分类
	 async gettopmenu(){
		 const ll= this.$i18n.locale 
		 console.log('ll',ll);
		 this.uniacid =window.localStorage.getItem('uniacid');
         //let uniacid =17;
	 	let res = await this.$http.get('/user/topmenu',{uniacid:this.uniacid,locale:ll})
	 	if(res){
	 		 this.menu= res.data 
			 console.log(this.menu);


            if(ll === 'zh-CN') {
                for (let i = 0; i < res.data.length; i++) {
                    const str = chineseLanguageLoader(res.data[i].name, { language: 'zh-CN' });
                    this.menu[i].name = str;
                }
            }
            else if(ll === 'EN' && this.uniacid == 17) {
                console.log('ENNNNNNNNNNNNNNNNNN')
                this.menu[1].name = "Professional Toothbrush Series";
                this.menu[2].name = "Long Handle Interdental Brush";
            }
	 	 
	 	}
		
	 },
		
        searchProduct() {
            this.keyword && this.$router.push({name: 'ProductList', params:{keyword: this.keyword}})
        }
    },
	  watch: {
		    'locale':function(locate){
				  console.log('change locale....');
				}
		  }
}
